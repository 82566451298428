.base-layout {
    height: 100vh;

    background: rgb(0, 141, 41);
    background: radial-gradient(
      circle,
      rgba(0, 141, 41, 1) 15%,
      rgba(0, 87, 12, 1) 71%,
      rgba(0, 45, 0, 1) 100%
    );
  
    display: flex;
    // align-items: center;
    justify-content: center;
  
    overflow-y: auto;
}
