@import './scss/index.scss';

.MuiInputLabel-root {
  color: #fff !important;
}

.MuiIconButton-label {
  color: #eee !important;
}

.MuiAvatar-circle {
  width: 32px !important;
  height: 32px !important;
  @include desktop {
    width: 52px !important;
    height: 52px !important;
  }
}

.pixels-container {
  padding-top: 20px;
  @include desktop {
    padding-top: 50px;
  }
  .MuiBox-root {
    padding-top: 40px !important;
    @include desktop {
      padding-top: 30px !important;
    }
  }

  .MuiTypography-root {
    font-size: 0.8rem !important;
    @include desktop {
      font-size: 1.25rem !important;
    }
  }
}

.Mui-error {
  color: #ebdb00 !important;

  & fieldset {
    border-color: #b9ad00 !important;
  }
}

.favoriteBeer {
  width: 100%;
  & > .MuiFormLabel-root {
    color: #ccffdc !important;
  }
}

.radioOptions-favoriteBeer {
  justify-content: space-between;
  .MuiFormControlLabel-label {
    font-size: 0.89em;
  }
}

.whenYouDrink {
  width: 100%;
  & > .MuiFormLabel-root {
    color: #ccffdc !important;
  }
}

.radioOptions-whenYouDrink {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  .MuiFormControlLabel-label {
    font-size: 0.89em;
  }
}
